<template>
  <div class="bg-white p-2 w-full border rounded">
    <v-date-picker v-model="selected.date" mode="dateTime" :minute-increment="10">
      <template
          #default="{ inputValue, togglePopover, hidePopover,showPopover }" @popoverDidHide='popOverHidden'>
        <div class="flex flex-wrap" ref="parent">
          <h3>

          <div
            v-for="(date, i) in dates"
            :key="i"
            class="badge badge-light m-2"
            @click.stop="dateSelected($event, date, togglePopover, showPopover, i)"
            ref="button"
          >
            <span v-if='!date.date'>
              select date
            </span>
            <span v-if='date.date'>
            {{ date.date.toLocaleString() }}
            </span>
            <i class='fa fa-close' 
              @click.stop="removeDate(i, hidePopover)"
            ></i>
          </div>
          </h3>
        </div>
      </template>
    </v-date-picker>
    <div
      class="badge badge-success"
      @click.stop="addDate"
    >
      + Add Date
    </div>
  </div>
</template>
<script>
export default {
  props: ['value'],
  data() {
    return {
      selected: {},
      dateTargetIndex: null,
      calendarShow: null,
      dates: []
    };
  },
  methods: {
    addDate() {
      console.log(this.dates);
      this.dates.push({
        date: null,
      });
      this.$nextTick(() => {
        const btn = this.$refs.button[this.$refs.button.length - 1];
        btn.click();
      });
      this.$emit('input', this.dates);
    },
    removeDate(i, hide) {
      this.dates.splice(i);
      hide();
      this.$emit('input', this.dates);
    },
    dateSelected(e, date, toggle, show, i) {
      this.selected = date;
      this.calendarShow = show;
      this.dateTargetIndex = i;

      toggle({ ref: e.target });
    },
    popOverHidden() {
      this.dateTargetIndex = null;
    }
  },
  updated() {
    this.$nextTick(function () {
      if (this.dateTargetIndex != null) {
        var btn = this.$refs.button[this.dateTargetIndex];
        this.calendarShow({ ref: btn });
      }
      
    })
  },
  watch: {
    value: {
      immediate: true,
      handler() {
        this.dates = this.value;
      }
    },
    // dates() {
    //   this.$emit('input', this.dates);
    // }
  }
};
</script>